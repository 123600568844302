import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { isEmpty } from 'utils/helpers';
import FiveDigitZip from './FiveDigitZip';
import { useIsZipSet } from 'utils/useGeoData';

const GeoZipWhite = ({ id, value = '', ...other }) => {
  const { setValue } = useFormContext();
  const geoData = useSelector((state: AppState) => state?.layout?.geoData);
  const shouldSetValue = useSelector((state: AppState) => (state.layout.isZipSet ? undefined : !isEmpty(value) ? undefined : state?.layout?.geoData?.postal));

  const isZipSet = useIsZipSet();
  console.log({ id, value, shouldSetValue, geoData });
  React.useEffect(() => {
    console.log('Use effect');
    if (shouldSetValue) {
      console.log('Setting value', { id, shouldSetValue });
      isZipSet({ isZipSet: true }); // Flag the zip code as sent, so it is only set once
      shouldSetValue && setValue(id, shouldSetValue);
    }
  }, [id, shouldSetValue, isZipSet, setValue]);
  return (
    <FiveDigitZip
      id={id}
      value={value}
      {...other}
      label={''}
      variant='standard'
      inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
      InputProps={{ disableUnderline: true, sx: { fontSize: 29, textAlign: 'center' } }}
    />
  );
};

export default GeoZipWhite;
