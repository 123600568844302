import React from 'react';
import MaskedInput from 'react-text-mask';
import Text from './Text';

export default function FiveDigitZip({ label = 'Zip Code', placeholder = 'Zip', sx = undefined, inputProps: iProps = undefined, ...other }) {
  return (
    <MaskedInput
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholder={placeholder}
      render={(ref, inputProps) => <Text label={label} inputProps={{ ref, ...inputProps, ...iProps, type: 'tel', autocomplete: 'off' }} {...other} />}
    />
  );
}
